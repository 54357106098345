import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/prevencion.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const Prevencion = props => (
  <Layout
    title="Servicio Prevención de Lavado de Dinero"
    description="Con nuestra Solución Contable le garantizamos que usted dejará de trabajar para su contador; a través de nuestros avanzados sistemas tecnológicos le ofrecemos una solución pertinente, rápida y efectiva. Únicamente requerimos sus Estados de Cuenta bancarios semanalmente, y nosotros nos encargamos del resto."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.prevencion.title" />
      </TitleSection>
      <div>
        <h2>
          <FormattedHTMLMessage id="solutions.prevencion.content.title" />
        </h2>
        {_.map(_.times(3, String), (value, key) => (
          <p key={key}>
            <FormattedHTMLMessage
              id={`solutions.prevencion.content.p${value}`}
            />
          </p>
        ))}
        <ul>
          {_.map(_.times(7, String), (value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.prevencion.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>

    <FooterContact />
  </Layout>
)

export default Prevencion
